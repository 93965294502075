<template>
    <mercur-card class="full-height-layout fill mx-4">
        <grid-header :quickSearch.sync="filters.search">History</grid-header>
        <data-table
            class="border"
            :url="url"
            :options="options"
            ref="grid"
            :quickSearch="filters.search"
        ></data-table>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import DataTable from '@/components/DataTable'
import GridHeader from '@/components/GridHeader'

export default {
    name: 'SupplierOrderlinesHistory',
    components: { GridHeader, DataTable },
    data () {
        return {
            filters: {
                search: '',
            },
            loading: false,
            options: {
                columns: {
                    'Order line Ids': {
                        field: 'entityIds',
                        valueGetter (params) {
                            let entityIds = params.data.entityIds

                            if (entityIds === null) {
                                return ''
                            }
                            if (typeof entityIds === 'string') {
                                return entityIds
                            }

                            if (entityIds === Object(entityIds) && !Array.isArray(entityIds)) {
                                entityIds = [entityIds]
                            }
                            return entityIds.map(orderLine => {
                                if (typeof orderLine === 'string') {
                                    return orderLine
                                }
                                if (Array.isArray(orderLine)) {
                                    return orderLine.shift()
                                }
                                if (orderLine.hasOwnProperty('orderLineNumber')) {
                                    return orderLine.orderLineNumber
                                }

                                if (orderLine.hasOwnProperty('orderLineId')) {
                                    return orderLine.orderLineId
                                }

                                return JSON.stringify(orderLine)
                            })
                        },
                    },
                    'Date': {
                        field: 'dateCreated',
                        sortable: true,
                    },
                    'Message': {
                        field: 'historyDescription',
                    },
                    'State': {
                        field: 'historyStatus',
                        statusChip: true,
                    },
                },
                sortModel: [{
                    colId: 'dateCreated',
                    sort: 'desc',
                }],
                quickSearchColumns: ['entityIds', 'historyDescription'],
            },
        }
    },
    computed: {
        url () {
            return CONFIG.API.ROUTES.SUPPLIERS.HISTORY.ORDERLINES.OVERVIEW.replace('{supplierId}', this.supplierId)
        },
    },
}
</script>
